<template>
    <v-row class="m-header m-0">
        <v-col cols="3">
            <v-img class="m-humburger" src="../assets/mobile/hamburger.svg"
                @click="toggleHandler('menu', toggle !== 'menu')">

            </v-img>
            <div class="m-humburger-card slide-in" v-if="toggle == 'menu'">
                <ul>
                    <li :class="getCurrentTab == 'home' ? 'active' : ''" @click="setActiveTab('home')">{{ $lan(12) }}</li>
                     <li :class="getCurrentTab == 'au' ? 'active' : ''" @click="setActiveTab('au')">{{ $lan(8) }}</li>
                    <li :class="getCurrentTab == 'tb' ? 'active' : ''" @click="setActiveTab('tb')">{{ $lan(4) }}</li>
                    <li :class="getCurrentTab == 'hiw' ? 'active' : ''" @click="setActiveTab('hiw')">{{ $lan(9) }}
                        {{ $lan(55) }}</li>
                    <li :class="getCurrentTab == 'wtb' ? 'active' : ''" @click="setActiveTab('wtb')">{{ $lan(10) }}</li>
                    <li :class="getCurrentTab == 'git' ? 'active' : ''" @click="setActiveTab('git')">{{ $lan(11) }}</li>
                    <li  @click="$store.commit('setPopupVisible', true)">{{ $lan(61) }}</li>
                </ul>
            </div>
        </v-col>
        <v-col cols="6">
            <v-img class="m-logo" src="../assets/logo.gif"></v-img>

        </v-col>
        <v-col cols="3">
            <span>
                <v-img class="m-lang" src="../assets/home/lang.svg"
                    @click="toggleHandler('lan', toggle !== 'lan')"></v-img>
                <div class="m-lan-card slide-in-1" v-if="toggle == 'lan'">
                    <ul>
                        <li :dataSelected="getLan" :class="getLan == 'en' ? 'active' : ''" @click="setLanguage('en')">
                            {{ $lan(13) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'hi' ? 'active' : ''" @click="setLanguage('hi')">
                            {{ $lan(14) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'mar' ? 'active' : ''" @click="setLanguage('mar')">
                            {{ $lan(15) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'gujarati' ? 'active' : ''"
                            @click="setLanguage('gujarati')">{{ $lan(57) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'bengali' ? 'active' : ''"
                            @click="setLanguage('bengali')">{{ $lan(58) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'telugu' ? 'active' : ''"
                            @click="setLanguage('telugu')">{{ $lan(59) }}</li>
                    </ul>
                </div>
            </span>
            <span></span>
        </v-col>
    </v-row>
    <v-row class="m-main-page">
        <home></home>
        <about-us></about-us>
    <template v-if="!isLoader">
        <our-partner></our-partner>
        <how-it-work></how-it-work>
        <why-tata-book></why-tata-book>
        <get-in-touch></get-in-touch>
    </template>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import AboutUs from './mobile/AboutUs.vue'
import GetInTouch from './mobile/GetInTouch.vue'
import Home from './mobile/Home.vue'
import HowItWork from './mobile/HowItWork.vue'
import OurPartner from './mobile/OurPartner.vue'
import WhyTataBook from './mobile/WhyTataBook.vue'

export default {
    components: { OurPartner, AboutUs, Home, HowItWork, WhyTataBook, GetInTouch },
    computed: {
        ...mapGetters(['getLan', 'getCurrentTab'])
    },
    data() {
        return {
            isActive: false,
            islan: false,
            toggle: '', 
            isLoader: true
        }
    },
    created(){
        let that = this
        setTimeout(()=>{
            that.isLoader = false
        },2000)
    },
    methods: {
        toggleHandler(key, value) {
            try {
                if (value) {
                    this.toggle = key
                } else {
                    this.toggle = ''
                }
            } catch (err) {
                console.error(err);
            }

        },
        setLanguage(value) {
            this.toggle = ''
            this.$store.commit('setLan', value)
        },
        setActiveTab(value) {
            this.scroll(value)
            this.toggle = ''
            this.$store.commit('setCurrentTab', value)
        },
        scroll(element) {
            var ele = document.getElementById(element);
            window.scrollTo(ele.offsetLeft, ele.offsetTop);
        }

    },

}
</script>

<style>
.m-header {
    margin: 0px;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 74px;
    background: #000;
}

.m-header .v-col {
    height: 74px;
    margin: 0px;
    padding: 0px;
    align-content: center;
}

.m-header .v-col:nth-child(3),
.m-header .v-col:nth-child(1) {
    text-align: -webkit-center;
}

.m-logo {
    height: 70px;
}

.m-humburger {
    height: 36px;
}

.m-lang {
    width: 28px;
    height: 28px;
}

.m-home {
    height: 100%;
}

.m-main-page {
    margin-top: 74px !important;
}

.m-humburger-card {
    /* height: 200px; */
    width: 200px;
    /* background: #FFC951; */
    position: absolute;
    z-index: 999;
    left: 20px;
    margin: 10px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 13px 7px #383535; */
    border-radius: 10px;
    /* animation: 3s alternate slidein; */
}

.slide-in-1 {
    animation: 1s alternate slidein;
}

.slide-in {
    animation: 2s alternate slidein;
}

@keyframes slidein {
    from {
        translate: 150vw 0;
        scale: 200% 1;
    }

    to {
        translate: 0 0;
        scale: 100% 1;
    }
}

.vis-low {
    animation: 3s alternate visibilitylowtohigh;
}

@keyframes visibilitylowtohigh {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.m-humburger-card ul {
    list-style: none;
}

.m-humburger-card li:last-child {
    border-radius: 0px 0px 10px 10px;
}

.m-humburger-card li:first-child {
    border-radius: 10px 10px 0px 0px;
}

.m-humburger-card li {
    letter-spacing: 2px;
    text-transform: uppercase;
    font: 12px;
    font-weight: bold;
    padding: 10px;
    text-align: left;
    background: #383535;
    margin-bottom: 1px;
    display: flex;
    justify-content: left;
}

.m-lan-card {
    /* height: 200px; */
    width: 120px;
    /* background: #FFC951; */
    position: absolute;
    z-index: 999;
    right: 5px;
    margin: 10px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 13px 7px #383535; */
    border-radius: 10px;
}

.m-lan-card ul {
    list-style: none;
}

.m-lan-card li:last-child {
    border-radius: 0px 0px 10px 10px;
}

.m-lan-card li:first-child {
    border-radius: 10px 10px 0px 0px;
}

.m-lan-card li {
    letter-spacing: 2px;
    text-transform: uppercase;
    font: 12px;
    font-weight: bold;
    padding: 10px;
    text-align: left;
    background: #383535;
    margin-bottom: 1px;
    display: flex;
    justify-content: left;
}


</style>