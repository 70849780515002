<template>
  <div class="text-center">
    <v-dialog v-model="getPopupVisible" max-width="400" persistent color="#000">
      <v-card text="" color="#fff" class="install-now">

        <template v-slot:title>
          <div class="head-install">
            <span>Tata</span>
            <span style="color:#fff"> &nbsp; book</span>
          </div>
        </template>
        <template v-slot:text>
          <div class="text-shadow-red lh-normal">
            Install our app for a better experience!
          </div>
        </template>


        <template v-slot:actions>

          <v-spacer></v-spacer>
          <v-img src="../assets/mobile/common/btn1.png" class="install-btn" @click="getItInstalled()">
            Install Now
          </v-img>
          <v-img src="../assets/mobile/common/btn3.png" class="install-btn"
            @click="$store.commit('setPopupVisible', false)">
            Maybe Later
          </v-img>

        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      deferredPrompt: '',
      isPopupVisible: false,
      isSafariPop: false,
      deviceType: ''
    }
  },
  computed: {
    ...mapGetters(['getPopupVisible'])
  },
  created() {
    if(window.location.hostname.includes('tataonline.co')) {
   

    // Example usage
    this.deviceType = this.detectDevice();
    if (this.deviceType === 'iOS' && !isPWAInstalled()) {
      // console.log('This is an iOS device.');
      this.$store.commit('setPopupVisible', true)
    }
    //  else if (this.deviceType === 'Android') {
    //   console.log('This is an Android device.');
    // } else {
    //   console.log('This is an unknown device.');
    // }
    // this.isPopupVisible = true
    let that = this
    // Show install prompt only for Safari
    if (this.isSafari()) {
      this.isSafariPop = true
    }
    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the default mini-infobar from appearing
      e.preventDefault();

      // Stash the event so it can be triggered later.
      that.deferredPrompt = e;
      this.$store.commit('setPopupVisible', true)
      // Show your custom install prompt UI
      //that.isPopupVisible = true
    });

    // Optionally, listen for the appinstalled event
    window.addEventListener('appinstalled', () => {
      console.log('PWA was installed');
      that.appInstalled();
    });
  } 

  },
  methods: {
    isPWAInstalled() {
      // Check if running in standalone mode (PWA installed)
      if (window.navigator.standalone) {
        // console.log('PWA is installed on the home screen (standalone mode).');
        return true;
      } else {
        // console.log('PWA is not installed on the home screen.');
        return false;
      }
    },
    detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if it's an iOS device
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }

      // Check if it's an Android device
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      return 'Unknown';
    },
    isSafari() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.includes('safari') && !userAgent.includes('chrome');
    },
    async getItInstalled() {
      try {
        if (this.deviceType === 'iOS' && !isPWAInstalled()) {
          if (navigator.share) {
            try {
              await navigator.share({
                title: 'Tata Online',
                text: 'Tata book Online',
                url: window.location.origin,
              });
              console.log('Content shared successfully!');
            } catch (error) {
              console.error('Error sharing:', error);
            }
          } else {
            alert('Web Share API is not supported on this browser.');
          }
        } else {
          // Hide the custom install prompt UI
          this.$store.commit('setPopupVisible', false)
          // alert("okay")
          // Show the browser's install prompt
          if (this.deferredPrompt) {
            this.deferredPrompt.prompt();

            // Wait for the user to respond to the prompt
            const { outcome } = await this.deferredPrompt.userChoice;
            console.log(`User response to the install prompt: ${outcome}`);

            // Clear the deferredPrompt variable
            this.deferredPrompt = null;
          }
        }
      } catch (error) {

      }
    },
    appInstalled() {
      try {

      } catch (error) {

      }
    }
  },
}
</script>

<style>
.install-btn {
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
}

.head-install {
  text-shadow: 2px 0 #F5BA37, -2px 0 #F5BA37, 0 2px #F5BA37, 0 -2px #F5BA37, 1px 1px #F5BA37, -1px -1px #F5BA37, 1px -1px #F5BA37, -1px 1px #F5BA37;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
}

.install-now {
  background: #fff;
  font-family: 'Oxanium';
}

#installPrompt {
  position: fixed;
  bottom: 20px;
  left: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: 400;
}

#installPrompt button {
  background-color: #EFAD19;
  color: #fff;
  padding: 5px 30px;
  width: fit-content;
  align-self: center;
  margin-top: 10px;
  border-radius: 20px;
}

.div2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 200px) and (max-width: 350px) {
  .install-btn {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .lh-normal {
    font-size: 14px;
  }

  .head-install {
    font-size: 20px;
  }
}
</style>