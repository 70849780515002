<template>
    <v-img src="../../assets/mobile/common/btn2.png" class="c-btn1" color="#000">
        {{ name }}
    </v-img>
</template>

<script>
export default {
props:{
    name:{
        default:'',
        type:String
    }
}
}
</script>

<style>
.c-btn1 {
    width: 239px;
    height: 55px;
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 2px;
    background-color: transparent !important;
    text-transform: uppercase;
}
</style>