<template>
    <div>
  <div class="swatch">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div></div>
</template>

<script>
export default {

}
</script>

<style scoped>
body {
	 background: #663399;
}
 .swatch {
	 display: block;
	 text-align: center;
	 position: fixed;
	 margin: 100px;
     top:20%;
}
 .swatch div {
	 width: 70px;
	 height: 225px;
	 position: absolute;
	 top: 0px;
	 border-radius: 5px;
	 border-top: solid 2px rgba(0,0,0,0.2);
	 border-left: solid 3px rgba(255,255,255,0.2);
	 border-bottom: solid 3px rgba(0,0,0,0.2);
	 text-align: center;
	 box-sizing: border-box;
	 transform-origin: center 90%;
	 display: inline-block;
	 backface-visibility: hidden;
	 margin-left: -35px;
	 transform: rotate(0deg);
}
 .swatch div:before {
	 width: 16px;
	 height: 16px;
	 content: "";
	 background-color: #FFFFFF;
	 display: inline-block;
	 border-radius: 8px;
	 bottom: 10px;
	 position: absolute;
	 margin-left: -8px;
}
 .swatch div:nth-child(1) {
	 background-color: #815A8F;
	 animation: swatch-purple-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 .swatch div:nth-child(2) {
	 background-color: #6730EC;
	 animation: swatch-blue-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 .swatch div:nth-child(3) {
	 background-color: #9ED763;
	 animation: swatch-green-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 .swatch div:nth-child(4) {
	 background-color: #FBD400;
	 animation: swatch-yellow-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 .swatch div:nth-child(5) {
	 background-color: #FF9000;
	 animation: swatch-orange-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 .swatch div:nth-child(6) {
	 background-color: #F73F52;
	 animation: swatch-red-motion 5s cubic-bezier(0.4,0.0,0.2,1) infinite;
}
 @keyframes swatch-purple-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(-65deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 @keyframes swatch-blue-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(-40deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 @keyframes swatch-green-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(-15deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 @keyframes swatch-yellow-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(15deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 @keyframes swatch-orange-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(40deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 @keyframes swatch-red-motion {
	 0% {
		 transform: rotate(0deg);
	}
	 30%, 70% {
		 transform: rotate(65deg);
	}
	 90%, 10% {
		 transform: rotate(0deg);
	}
}
 
</style>