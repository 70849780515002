<template>
  <v-col cols="12" class="m-how-it-work" id="hiw">
    <v-img src="../../assets/mobile/howitwork/bg1.png" class="how-it-work-bg">
      <div class="ab-head1">{{ $lan(21) }}</div>
      <div class="wlc f-drop-shadow">
        <div class="text-shadow-red">
          <span>{{ $lan(9) }} </span>
          <span> {{ $lan(55) }} </span>
        </div>
      </div>
      <div class="h-card">
        <v-img src="../../assets/mobile/howitwork/1.png">
          <div class="h-number">
            <span> {{ $lan(56) }} </span>
            <span> 1 </span>
          </div>
          <div class="h-desc" style="color:#00ff86">
            {{ $lan(22) }}
          </div>
        </v-img>
      </div>

      <div class="h-card">
        <v-img src="../../assets/mobile/howitwork/2.png">
          <div class="h-number" style="color:#fff">
            <span> {{ $lan(56) }} </span>
            <span> 2 </span>
          </div>
          <div class="h-desc" style="color:#DA1D25">
            {{ $lan(23) }}
          </div>
        </v-img>
      </div>

      <div class="h-card">
        <v-img src="../../assets/mobile/howitwork/3.png">
          <div class="h-number">
            <span> {{ $lan(56) }} </span>
            <span> 3 </span>
          </div>
          <div class="h-desc" style="color:#dbff00">
            {{ $lan(24) }}
          </div>
        </v-img>
      </div>

      <div class="h-card">
        <v-img src="../../assets/mobile/howitwork/4.png">
          <div class="h-number" style="color:#fff">
            <span> {{ $lan(56) }} </span>
            <span> 4 </span>
          </div>
          <div class="h-desc" style="color:#f261ff">
            {{ $lan(25) }}
          </div>
        </v-img>
      </div>
    </v-img>
  </v-col>
</template>

<script>
export default {

}
</script>

<style scoped>
.m-how-it-work {
  padding: 0px;
}

.how-it-work-bg {
  text-align: -webkit-center;
}

.h-card {
  margin-left: 55px;
  margin-right: 55px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.h-number {
  height: 55%;
  align-content: center;
  display: inline-grid;
  color: #000;
}

.h-number span:nth-child(1) {
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 700;
}

.h-number span:nth-child(2) {
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 700;
}

.h-desc {
  height: 30%;
  align-content: center;
  padding: 0px 10px;
  font-size: 14px;
}

.text-shadow-red {
  text-shadow: 4px 0px 15px #FF0000;
}
.m-about-us  div {
  text-align: -webkit-center;
  padding: 10px;
}
.ab-head1 {
  text-align: -webkit-center;
  padding: 30px 10px 0px 10px !important;
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 2px;
  color:#000;
}

.f-drop-shadow {
  filter: drop-shadow(4px 10px 32px #FF0000);
}

.f-drop-shadow div {
  text-shadow: 2px 0 #F5BA37, -2px 0 #F5BA37, 0 2px #F5BA37, 0 -2px #F5BA37,
    1px 1px #F5BA37, -1px -1px #F5BA37, 1px -1px #F5BA37, -1px 1px #F5BA37;
}

.f-drop-shadow div span:nth-child(2) {
  color: #fff;
}

.f-drop-shadow div span:nth-child(1) {
  color: #000;
}
.wlc {
  font-weight: bold;
  font-size: 45px;
  text-transform: uppercase;
}
</style>