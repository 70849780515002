<template>
  <v-container class="sportid" id="aboutus" fluid>
    <v-row justify="center">
      <v-col cols="6" class="s-head">
        <v-img src="../assets/sportid/title.svg"></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="3" md="2" sm="2" class="s-col-1 sp-height"></v-col>
      <v-col lg="3" md="2" sm="2" class="s-col-2 sp-height"></v-col>
      <v-col lg="3" md="2" sm="2" class="s-col-3 sp-height"></v-col>
      <v-col lg="3" md="2" sm="2" class="s-col-4 sp-height"></v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
};
</script>
<style scoped>
.sportid {
  background: url("../assets/sportid/bg1.svg"), url("../assets/sportid/bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 726px;
  align-content: center;
}

.s-col-1 {
  background: url("../assets/sportid/card1.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.s-col-2 {
  background: url("../assets/sportid/card2.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.s-col-3 {
  background: url("../assets/sportid/card3.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.s-col-4 {
  background: url("../assets/sportid/card4.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.sp-height {
  min-height: 310px;
}
.s-head {
  min-height: 130px;
  align-content: center;
}
@media only screen and (max-width: 768px) {
  .sportid {
    min-height: 358px;
  }
  .sp-height {
    min-height: 125px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .sportid {
    min-height: 466px;
  }
  .sp-height {
    min-height: 160px;
  }
}
</style>
