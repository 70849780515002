<template>
  <v-col class="why-head" id="wtb">
    <div class="text-shadow-red">{{ $lan(26) }}</div>
    <div class="wlc f-drop-shadow">
      <div class="text-shadow-red lh-normal">
        <span>{{ $lan(27) }} </span><br>
        <span style="color: #FEEF85;">{{ $lan(4) }}</span>
      </div>
    </div>
  </v-col>
  <v-col cols="12" class="m-why-tata-book">
    <v-img class="why-bg" src="../../assets/mobile/why/1.png">
      <div class="wl-0"> </div>
      <div class="wl-1"> {{ $lan(28) }}</div>
      <div class="wl-2">{{ $lan(29) }}</div>
      <div class="wl-3">
        {{ $lan(30) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
    <v-img class="why-bg" src="../../assets/mobile/why/2.png">
      <div class="wl-0"> </div>
      <div class="wl-1" style="color:#FFDD15;"> {{ $lan(32) }}</div>
      <div class="wl-2">{{ $lan(33) }}</div>
      <div class="wl-3" style="color:#FFDD15;">
        {{ $lan(34) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
    <v-img class="why-bg" src="../../assets/mobile/why/3.png">
      <div class="wl-0"> </div>
      <div class="wl-1"> {{ $lan(35) }}</div>
      <div class="wl-2">{{ $lan(36) }}</div>
      <div class="wl-3">
        {{ $lan(37) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
    <v-img class="why-bg" src="../../assets/mobile/why/4.png">
      <div class="wl-0"> </div>
      <div class="wl-1"> {{ $lan(38) }}</div>
      <div class="wl-2">{{ $lan(39) }}</div>
      <div class="wl-3">
        {{ $lan(40) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
    <v-img class="why-bg" src="../../assets/mobile/why/5.png">
      <div class="wl-0"> </div>
      <div class="wl-1"> {{ $lan(41) }}</div>
      <div class="wl-2">{{ $lan(42) }}</div>
      <div class="wl-3">
        {{ $lan(43) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
    <v-img class="why-bg" src="../../assets/mobile/why/6.png">
      <div class="wl-0"> </div>
      <div class="wl-1"> {{ $lan(44) }}</div>
      <div class="wl-2">{{ $lan(1) }}45</div>
      <div class="wl-3">
        {{ $lan(46) }}
      </div>
      <div class="wl-4">{{ $lan(31) }}</div>
    </v-img>
  </v-col>
</template>

<script>
export default {

}
</script>

<style>
.why-head {
  text-align: center;
}
.why-bg {
  height: 474px;
  margin: 30px;
  text-transform: uppercase;
  text-align: center;
}

.wl-0 {
  padding: 69px;
}

.wl-1 {
  /* position: relative;
    top: 40%;
    left: 40%; */
  color: #01D370;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
  height: 86px;
  align-content: center;
}

.wl-2 {
  /* position: absolute;
    top: 47%;
    left: 15%; */
  font-size: 25px;
  font-weight: bold;
  height: 60px;
  align-content: center;
  height: 88px;
  align-content: center;
}

.wl-3 {
  /* position: absolute;
    top: 65%;
    left: 15%; */
  color: #01D370;
  font-size: 15px;
  font-weight: bold;
  padding: 15px;

}

.why-bg:nth-child(2) .wl-0 {
  padding: 53px;
}

.wl-4 {
  /* position: absolute;
    top: 85%;
    left: 34%; */
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 25px;
}

@media only screen and (max-width: 375px) {
  .wl-0 {
    padding: 55px;
  }

  .wl-1 {
    height: 75px;
    font-size: 12px;
  }

  .wl-2 {
    font-size: 25px;
    height: 45px
  }

  .wl-3 {
    font-size: 14px;
    height: 82px;
    align-content: center;
  }

  .wl-4 {
    font-size: 14px;
  }

  .why-bg {
    height: 390px;
  }

  .why-bg:nth-child(2) .wl-0 {
    padding: 47px;
  }
}

@media only screen and (max-width: 320px) {
  .wl-0 {
    padding: 43px;
  }

  .wl-1 {
    height: 60px;
    font-size: 10px;
  }

  .wl-4 {
    font-size: 12px;
  }

  .wl-2 {
    font-size: 20px;
    height: 40px;
  }

  .wl-3 {
    font-size: 12px;
    height: 62px;
    align-content: center;
  }

  .why-bg:nth-child(2) .wl-0 {
    padding: 35px;
  }

  .why-bg {
    height: 310px;
  }
}
</style>