<template>
  <v-col cols="12" class="m-about-us" id="au">
    <v-img src="../../assets/mobile/aboutus/bg1.png">
      <div class="ab-head1">{{ $lan(8) }}</div>
      <div class="wlc f-drop-shadow">
        <div class="text-shadow-red lh-normal">
          <span>{{ $lan(17) }} </span>
          <span>{{ $lan(4) }}</span>
        </div>
      </div>
      <div class="text-shadow-red">
        {{ $lan(18) }}
      </div>
      <div>
        <btn-1 :name="$lan(19) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-1>
      </div>
      <div>
        <btn-2 :name=" $lan(5) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-2>
      </div>

    </v-img>
  </v-col>
</template>

<script>
import Btn1 from './Btn1.vue'
import Btn2 from './Btn2.vue'
export default {
  components: { Btn1, Btn2 },

}
</script>

<style>
.lh-normal {
  line-height: normal;
}
.text-shadow-red {
  text-shadow: 4px 0px 15px #FF0000;
}
.m-about-us  div {
  text-align: -webkit-center;
  padding: 10px;
}
.ab-head1 {
  text-align: -webkit-center;
  padding: 30px 10px 0px 10px !important;
  font-weight: 800;
  font-size: 18px;
}

.f-drop-shadow {
  filter: drop-shadow(4px 10px 32px #FF0000);
}

.f-drop-shadow div {
  text-shadow: 2px 0 #F5BA37, -2px 0 #F5BA37, 0 2px #F5BA37, 0 -2px #F5BA37,
    1px 1px #F5BA37, -1px -1px #F5BA37, 1px -1px #F5BA37, -1px 1px #F5BA37;
}

.f-drop-shadow div span:nth-child(1) {
  color: #fff;
}

.f-drop-shadow div span:nth-child(2) {
  color: #FEEF85;
}

.wlc {
  font-weight: bold;
  font-size: 45px;
  text-transform: uppercase;
}
</style>