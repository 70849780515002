<template>
  <v-responsive>
    <v-app>
      <install-vue></install-vue>
      <social :top="top"></social>
      <v-main v-if="this.$vuetify.display.width < 769">
        <mobile></mobile>
      </v-main>
      <v-main v-else>
        <Home />
        <v-container fluid>
          <AboutUsVue />
        </v-container>
        <PartnerVue />
        <SportIdVue />

        <ServicesVue />
        <v-container fluid>
          <ContactUsVue />
        </v-container>
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script>
import AboutUsVue from "./components/AboutUs.vue";
import ContactUsVue from "./components/ContactUs.vue";
import Home from "./components/Home.vue";
import PartnerVue from "./components/Partner.vue";
import ServicesVue from "./components/Services.vue";
import SportIdVue from "./components/SportId.vue";
import Mobile from "./components/Mobile.vue";
import installVue from './components/install.vue';
import Social from './components/Social.vue';
import Animation from './components/animation.vue';

export default {
  name: "App",

  components: {
    Home,
    AboutUsVue,
    SportIdVue,
    ServicesVue,
    ContactUsVue,
    PartnerVue,
    Mobile,
    installVue,
    Social,
    Animation
  },
  data() {
    return {
      lastKnownScrollPosition: 0,
      ticking: false,
      top: 0,
      innerHeight: 0
    }
  },
  mounted() {
    let that = this
    this.innerHeight = window.innerHeight
    if (this.top == 0)
      this.top = (this.lastKnownScrollPosition + this.innerHeight) / 2
    document.addEventListener("scroll", (event) => {
      that.lastKnownScrollPosition = window.scrollY;
      that.innerHeight = window.innerHeight
      if (that.top == 0)
        that.top = (that.lastKnownScrollPosition + (that.innerHeight / 2))
      if (!that.ticking) {
        window.requestAnimationFrame(() => {
          that.doSomething(that.lastKnownScrollPosition);
          that.ticking = false;
        });

        that.ticking = true;
      }
    });
    window.addEventListener('scrollend', () => {
      that.lastKnownScrollPosition = window.scrollY;
      this.top = (this.lastKnownScrollPosition + (this.innerHeight / 2))
    });
    console.log(this.$vuetify.display.width)
  },
  methods: {
    doSomething(scrollPos) {
      console.log("top", this.top);
      console.log("scrollPos", scrollPos);
      console.log("innerHeight", this.innerHeight);
      if ((this.top-100) < scrollPos)
        this.top = (scrollPos + (this.innerHeight / 2))
      else if (this.top - (this.innerHeight-100) > scrollPos) {
        this.top = (scrollPos + (this.innerHeight / 2))
      }
    }
  },
};
</script>
<style >
* {
  font-family: Oxanium;
}
.mdi-whatsapp {
  color:#fff!important;
}
.active {
    background: #F5BA37 !important;
    color: #000;
}
.cp {
  cursor: pointer;
}
.v-btn--icon .v-icon {
  --v-icon-size-multiplier:1.3;
}
.whtbtn {
  margin:10px;
  /* width:180px; */
  background: rgb(37, 211, 102);
  color:#fff;
  /* height:50px; */
}
</style>
