<template>
  <v-container fluid id="home">
    <span class="bottom-right">
      <v-img src="../assets/home/bottomRight.svg"></v-img>
    </span>
    <v-row>
      <v-col cols="12" class="nav-bar-home">
        <span class="nav-logo">
          <v-img src="../assets/logo.gif"></v-img>
        </span>
        <span class="nav-item" @click="setActiveTab('home')" :class="getCurrentTab == 'home' ? 'selected':''">{{ $lan(12) }}</span>
        <span class="nav-item" @click="setActiveTab('services')" :class="getCurrentTab == 'services' ? 'selected':''">{{ $lan(8) }}</span>
        <span class="nav-item" @click="setActiveTab('plateforms')" :class="getCurrentTab == 'plateforms' ? 'selected':''">{{ $lan(4) }}</span>
        <span class="nav-item" @click="setActiveTab('aboutus')" :class="getCurrentTab == 'aboutus' ? 'selected':''">{{ $lan(9) }}</span>
        <span class="nav-item" @click="setActiveTab('sportid')" :class="getCurrentTab == 'sportid' ? 'selected':''">{{ $lan(10) }}</span>
        <span class="nav-item" @click="setActiveTab('contactus')" :class="getCurrentTab == 'contactus' ? 'selected':''">{{ $lan(11) }}</span>
        <span class="nav-lang">
          <div class="nav-lang-icon">
            <v-img src="../assets/home/lang.svg" class="cp" @click="toggleHandler('lan', toggle !== 'lan')"></v-img>
            <div class="m-lan-card slide-in-1 cp" v-if="toggle == 'lan'">
                    <ul>
                        <li :dataSelected="getLan" :class="getLan == 'en' ? 'active' : ''" @click="setLanguage('en')">
                            {{ $lan(13) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'hi' ? 'active' : ''" @click="setLanguage('hi')">
                            {{ $lan(14) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'mar' ? 'active' : ''" @click="setLanguage('mar')">
                            {{ $lan(15) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'gujarati' ? 'active' : ''"
                            @click="setLanguage('gujarati')">{{ $lan(57) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'bengali' ? 'active' : ''"
                            @click="setLanguage('bengali')">{{ $lan(58) }}</li>
                        <li :dataSelected="getLan" :class="getLan == 'telugu' ? 'active' : ''"
                            @click="setLanguage('telugu')">{{ $lan(59) }}</li>
                    </ul>
                </div>
          </div>
          <div class="selected-lan cp" @click="toggleHandler('lan', toggle !== 'lan')">
            {{ $lan(getLanOption()) }}
          </div>
        </span>
      </v-col>
    </v-row>
    <v-row class="home" justify="center">
      <v-col lg="4" md="5" sm="5" class="h-col-2">
        
        <v-row justify="center slide-in">
                    <v-col cols="auto">
                        <p class="head1">{{ $lan(1) }}</p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto" class="wlc f-drop-shadow">
                        <p><span> {{ $lan(2) }} </span><span>&nbsp;{{ $lan(3) }} <br> {{ $lan(4) }}</span></p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="6">
                          <btn-1 :name=" $lan(5) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-1>
                    </v-col>
                    <v-col cols="6">
                          <btn-2 :name=" $lan(6) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-2>
                    </v-col>
                </v-row>
        <!-- <v-img src="../assets/home/head.svg" /> -->
      </v-col>
      
    </v-row>

    <v-row class="home3" justify="center">
      <v-col lg="4" md="5" sm="6" class="h3-col-1">
        <v-img src="../assets/home/computer.svg"></v-img>
      </v-col>
      <v-col lg="4" md="5" sm="6" class="h3-col-2">
        <v-row justify="center slide-in">
                    <v-col cols="auto">
                        <p class="head1">{{ $lan(7) }}</p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto" class="wlc f-drop-shadow">
                        <p><span>{{ $lan(50) }} </span><span>{{ $lan(62) }}</span></p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto" class="d-flex">
                           <v-btn   prepend-icon="mdi-whatsapp"  size="x-large" class="whtbtn" @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')">+91 7070770704</v-btn>
                           <v-btn   prepend-icon="mdi-whatsapp"  size="x-large" class="whtbtn" @click="$wa('whatsapp://send?phone=917070770705&text=Hi%2C%20I%27m%20interested')">+91 7070770705</v-btn>
                    </v-col>
                </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import Btn1 from './mobile/Btn1.vue';
import Btn2 from './mobile/Btn2.vue';
export default {
  name: "Home",
  components: { Btn1, Btn2 },
  data: () => ({
    loading: true,
    toggle:'en'
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  computed: {
    ...mapGetters(['getCurrentTab', 'getLan'])
  },
  methods: {
    getLanOption(){
     let obj = {
      'en':13,
      'hi':14,
      'mar':15,
      'gujarati':57,
      'bengali':58,
      'telugu':59,
      }
      return obj[this.getLan]
    },
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) { }
    },
    toggleHandler(key, value) {
            try {
                if (value) {
                    this.toggle = key
                } else {
                    this.toggle = ''
                }
            } catch (err) {
                console.error(err);
            }

        },
        setLanguage(value) {
            this.toggle = ''
            this.$store.commit('setLan', value)
        },
        setActiveTab(value) {
            this.scroll(value)
            this.toggle = ''
            this.$store.commit('setCurrentTab', value)
        },
        scroll(element) {
            var ele = document.getElementById(element);
            window.scrollTo(ele.offsetLeft, ele.offsetTop);
        }
  },
};
</script>
<style scoped>
.whtbtn {
  margin:10px;
  /* width:180px; */
  background: rgb(37, 211, 102);
  color:#fff;
  /* height:50px; */
}
.selected-lan {
  padding:10px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
.selected {
  color: #EFAD19;
  height: 100%;
  border-bottom: 5px solid #EFAD19;
  align-content: center;
}

.bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 200px;
}

.nav-select {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../assets/home/arrow.svg") right no-repeat;
}

.nav-select:focus {
  border: none;
  outline: none;
}

.nav-lang {
  display: flex;
  align-content: center;
}

.nav-lang-icon {
  width: 34px;
  height: 34px;
}

.nav-logo {
  width: 238px;
}

.nav-bar-home {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav-item {
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.home {
  background-image: url("../assets/home/background.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1236px;
  box-shadow: 0px -85px 40px #000 inset;
}

.h-col-1 {
  min-height: 790px;
  display: flex;
  align-items: self-end;
}

.h-col-2 {
  align-content: center;
  place-self: start;
  padding-top: 230px;
}

.home2 {
  background: url("../assets/home/home2bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  margin-top: -250px;
}

.h2-col-1 {
  height: 660px;
}

.h3-col-2 {
  align-self: center;
}

.home3 {
  max-height: 338px;
}

@media only screen and (max-width: 768px) {
  .home {
    height: 585px;
  }

  .h-col-2 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    height: 785px;
  }

  .h-col-2 {
    padding-top: 115px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .home {
    height: 1100px;
  }

  .h-col-2 {
    padding-top: 180px;
  }
}
</style>

