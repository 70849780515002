import { createApp } from "vue";
import App from "./App.vue";
import Webhumsafar from "./Webhumsafar.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import store from "./store";
import lanObj from "./json/lan.json"
import Shoesandsandles from "./Shoesandsandles.vue";

loadFonts();
let main = ''
if(window.location.hostname.includes('webhumsafar.online')) {
    main = Webhumsafar
} else if(window.location.hostname.includes('tataonline.co')) {
    main = App
}  else if(window.location.hostname.includes('tataonline.club')) {
    main = App
} else if (window.location.hostname.includes('shoesandsandles.in')) {
    main = Shoesandsandles
}
const app = createApp(main)

app.use(store)
app.config.globalProperties.$wa = (url) => {
    if (url) window.open(url);
}
app.config.globalProperties.$lan = (key) => {
    try {
        let { lan } = app.config.globalProperties.$store.state
        if (lanObj[lan][key]) {
            return lanObj[lan][key]
        }
    } catch (err) {
        console.error(err);
        return ''
    }
}

app.use(vuetify)
app.mount("#app");





