<template>
    <v-col cols="12" class="m-home" id="home">
        <v-img src="../../assets/mobile/home/home2.png" class="vis-low">
            <v-container>
                <v-row justify="center slide-in">
                    <v-col cols="auto">
                        <p class="head1">{{ $lan(1) }}</p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto" class="wlc f-drop-shadow">
                        <p><span>{{ $lan(2) }} </span><span>{{ $lan(3) }} <br> {{ $lan(4) }}</span></p>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto">
                           <btn-1 :name=" $lan(5) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-1>
                    </v-col>
                </v-row>
                <v-row justify="center slide-in">
                    <v-col cols="auto">
                       <btn-2 :name=" $lan(6) " @click="$wa('whatsapp://send?phone=917070770704&text=Hi%2C%20I%27m%20interested')"></btn-2>
                       
                    </v-col>
                </v-row>
                <!-- <v-row justify="center">
                     <v-col class="sprite"> 
                        
                     </v-col>
                </v-row> -->
                <!-- <animation></animation> -->
            </v-container>
        </v-img>
    </v-col>
</template>

<script>
import Animation from '../animation.vue'
import Btn1 from './Btn1.vue'
import Btn2 from './Btn2.vue'
export default {
  components: { Btn1, Btn2, Animation },

}
</script>

<style >
.sprite {
    background: url('../../assets/gif/ball.gif');
    /* animation: ani 2s steps(5) ease-in-out; */
    background-repeat: no-repeat;
    height:50px;
    background-size: 50px;
}
@keyframes ani {
    from {
        background-position: 0;
    }
    to {
        background-position: 100%;
    }
}

.f-drop-shadow {
    filter: drop-shadow(4px 10px 32px #FF0000);
}
.f-drop-shadow p {
    text-shadow: 2px 0 #F5BA37, -2px 0 #F5BA37, 0 2px #F5BA37, 0 -2px #F5BA37,
               1px 1px #F5BA37, -1px -1px #F5BA37, 1px -1px #F5BA37, -1px 1px #F5BA37;
}
.f-drop-shadow p span:nth-child(1) {
    color: #fff;
}
.f-drop-shadow p span:nth-child(2) {
    color:#FEEF85;
}
.wlc {
    font-weight: bold;
    font-size: 45px;
    text-transform: uppercase;
}

.m-home {
    padding: 0px;
}

.head1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 71px;
    letter-spacing: 4px;
    text-transform: uppercase;
    animation: 1s ease-in-out;
}

@media only screen and (max-width: 320px) {
    .wlc {
        font-weight: bold;
        font-size: 40px;
        text-transform: uppercase;
    }
    .head1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 71px;
    letter-spacing: 4px;
    text-transform: uppercase;
}
}
</style>