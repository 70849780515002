import { createStore } from "vuex";

export default createStore({
  state: {
    currentTab:'home',
    lan:'en', 
    isPopupVisible:false,
  },
  getters: {
    getCurrentTab (state)  {
      return state.currentTab
    },
    getLan (state) {
      return state.lan
    }, 
    getPopupVisible (state) {
      return state.isPopupVisible
    }

  },
  mutations: {
    setCurrentTab (state, data) {
      state.currentTab = data
    }, 
    setLan (state, data) {
      state.lan = data
    }, 
    setPopupVisible (state, data) {
      state.isPopupVisible = data
    }
  },
  actions: {},
  modules: {},
});
