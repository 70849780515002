<template>
  <v-container fluid class="pb-0 pt-0">
    <v-img src="../assets/partner/bg.svg" class="align-i-center">
      <v-row>
        <!-- <v-col class="col-partner">
          <div class="ab-head1">{{ $lan(4) }}</div>
          <div class="wlc f-drop-shadow">
            <div class="text-shadow-red lh-normal">
              <span>{{ $lan(63) }} </span>
              <span><br>{{ $lan(64) }}</span>
            </div>
          </div>
          <div class="text-shadow-red">
            {{ $lan(65) }}
          </div>
        </v-col> -->
        <v-col class="col-partner">
          <v-img src="../assets/mobile/ourpartner/card3.png" width="650px" ></v-img>  
        </v-col>
      </v-row>
    </v-img>
  </v-container>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) { }
    },
  },
};
</script>
<style scoped>

.align-i-center {
  align-items: center;
}

.col-partner {
  align-self: center;
  padding: 0px;
  text-align: -webkit-center;
  padding-bottom: 0px!important;
}

</style>
